.chip {
  position: relative;
  height: 40px;
  padding: 8px;
  cursor: pointer;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  transform-origin: top left;
}

.chipHidden {
  display: none;
}

.chipCollapsed:not(.chipSelected) {
  pointer-events: none;
  opacity: 0;
}

.chipSelected {
  background-color: var(--background-accent-low);
  border-color: var(--background-accent-low);
}

.chip:disabled {
  cursor: not-allowed;
  position: relative;
  opacity: var(--disabled-opacity);
}

.wrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.content {
  composes: body-1 from global;
  color: var(--content-primary-a);
}

.clear {
  margin-left: 10px;
}

.clearIcon {
  display: flex;
  color: var(--content-primary-a);

  svg {
    width: 12px;
    height: 12px;
  }
}
