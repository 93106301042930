.wrapper {
  display: inline-flex;
  box-sizing: unset;
  align-items: center;
}

.toggle {
  display: inline-block;
  align-items: center;
  background-color: var(--background-toggle);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  height: 16px;
  padding: 4px;
  position: relative;
  transition: background-color 0.2s;
  width: 36px;
  box-sizing: content-box;
}

.children {
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.knob:hover + .overlay {
  background-color: var(--state-primary-a-hover);
}

.overlay:hover {
  background-color: var(--state-primary-a-hover);
}

.toggle:active .overlay {
  background-color: var(--state-primary-a-pressed);
}

.knob {
  background-color: white;
  border-radius: 8px;
  height: 16px;
  position: absolute;
  transform: translateX(0);
  transition:
    transform 0.2s,
    background-color 0.2s,
    width 0.2s;
  width: 16px;
}

.wrapper[data-selected] .toggle {
  background-color: var(--background-accent-high);
}

.wrapper[data-selected] .toggle .knob {
  background-color: white;
  transform: translateX(20px);
}

.toggle:active .knob {
  width: 25px;
}

.wrapper[data-selected] .toggle:active .knob {
  background-color: white;
  transform: translateX(11px);
}

.toggle:active {
  background-color: var(--background-accent-high);
}

.wrapper[data-disabled] .toggle {
  opacity: 0.5;
  pointer-events: none;
}

[data-focus-visible='true'] .toggle {
  outline: 4px solid var(--background-accent-low);
  border-radius: 12px;
}
