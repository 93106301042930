.table-cell {
  border-bottom: 1px solid var(--border-tertiary);
}

.table-cell:hover {
  &:not(.is-filler-column) {
    border-bottom: 1px solid var(--border-secondary);
  }
}

.table-cell-content {
  display: flex;
  align-items: center;
}

.fixed-column {
  display: flex;
  gap: 16px;
}
