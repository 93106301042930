.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 80px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1128px;
  padding: 0 48px;
  margin-bottom: 32px;
}

.content-block {
  padding: 16px 24px;
}

.video-wrapper {
  display: flex;
  margin-right: 24px;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
