.trigger--empty {
  position: absolute;
  inset: 0;
  visibility: hidden;
}

.apply-z-index {
  /* using !important to overwrite style from react-aria */
  z-index: var(--z-index-above) !important;
}

.popover {
  width: 288px;
  min-height: 136px;
  background-color: var(--elevation-high);

  /* --shadow-high with blur split by 2 due to differences in drop-shadow rendering */
  filter: drop-shadow(0 3px 6px rgba(29, 29, 27, 0.12));
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  opacity: 0;
  animation: popover-fade-in 250ms ease-out 250ms forwards;
}

@keyframes popover-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.arrow {
  position: absolute;
  display: flex;
  fill: var(--elevation-high);
  stroke: var(--border-tertiary);
}

.arrow--left {
  right: 0;
  margin-right: -12px;
}

.arrow--lower {
  bottom: 77px;
}

.arrow--upper {
  top: 51px;
  fill: var(--background-feature-discoverability);
}

.arrow--right {
  left: 0;
  margin-left: -12px;
}

.arrow--horizontally-centered {
  left: 50%;
  transform: translateX(-50%);
}

.arrow--top {
  bottom: 0;
  margin-bottom: -22px;
}

.arrow--bottom {
  top: 0;
  margin-top: -22px;
  fill: var(--background-feature-discoverability);
}

.arrow--bottom .arrow-icon {
  transform: rotate(-90deg);
}

.arrow--top .arrow-icon {
  transform: rotate(90deg);
}

.arrow--right .arrow-icon {
  transform: scale(-1, 1);
}

.arrow--right-corner {
  right: 14px;
}

.illustration-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 136px;
  background-color: var(--background-feature-discoverability);
  border-radius: 4px 4px 0 0;
}

.illustration {
  width: 130px;
  height: 130px;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.text {
  padding: 16px;
}

.description {
  color: var(--content-secondary);
}

.cta {
  margin-top: 8px;
}

.cta-icon {
  display: flex;
  align-items: center;
  margin-left: 8px;

  svg {
    color: var(--content-primary-b);
  }
}

.faq-link {
  font-size: 12px;
  line-height: 16px;
}
