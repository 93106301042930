.categorization-card {
  display: flex;
  flex-direction: column;
  min-width: 352px;
  padding: 16px;
  margin-top: 16px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.categorization-card-title {
  composes: body-1 from global;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.categorization-actions {
  display: flex;
  gap: 12px;
  justify-content: flex-start;
}

.categorization-actions button {
  white-space: nowrap;
}

.tooltip {
  display: -webkit-box;
  max-width: 304px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
