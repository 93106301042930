.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 24px;
  align-items: flex-start;

  dd {
    margin: 0;
  }
}

.term {
  display: flex;
  align-items: center;
}

.description {
  max-width: 312px;
  text-align: right;
}

.beneficiary-information {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.beneficiary-source {
  display: flex;
  align-items: center;
  min-height: 24px;
}

.beneficiary-currency {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 16px;
  padding: 4px;
  text-transform: uppercase;
  border: 1px solid var(--border-secondary);
  border-radius: 2px;
}

.tooltip {
  height: auto;
  padding: 0;
  font: inherit;
  vertical-align: middle;
  cursor: unset;
  background: none;
  border: none;
}

.icon {
  color: var(--content-secondary) !important;
}

.separator {
  width: 100%;
  height: 1px;
  margin: 0;
  background-color: var(--border-tertiary);
  border: 0;
}

.break-word {
  word-break: break-word;
}

.color-secondary {
  color: var(--content-secondary);
}
