.title {
  composes: caption-bold from global;
  margin-bottom: 16px;
  color: var(--content-secondary);
}

.select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
}

.select-placeholder {
  composes: body-1 from global;
  color: var(--content-tertiary);
}

.trigger {
  display: flex;
  gap: 8px;
  align-items: center;
  align-self: stretch;
  padding: 8px 12px;
  background-color: var(--elevation-default);
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
}

.trigger-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrow-icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-left: auto;
}

.popover {
  max-height: 310px;
  padding: 12px 0;
  overflow: auto;
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.section,
.list-box {
  display: flex;
  flex-direction: column;
}

.section-title {
  composes: caption from global;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  color: var(--content-secondary);
}

.parent-category {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  cursor: pointer;
}

.toggle-icon {
  width: 16px;
  height: 16px;
}

.category-list-item {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px 0 48px;

  &[data-hovered='true'] {
    background-color: var(--background-secondary);
  }

  &[data-selected='true'] {
    background-color: var(--state-selected);
  }
}

.category {
  display: flex;
  gap: 12px;
  align-items: center;
  max-width: 100%;
  color: var(--content-primary-a);
  cursor: pointer;

  .category-name {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.category-icon-container {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token */
  color: var(--primary-black);
}

.category-color-dot {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.disclaimer {
  margin-top: 16px;
}

.disclaimer-link {
  padding: 0 !important;
  color: var(--content-secondary) !important;
}

.category-block {
  position: relative;
}

.menu-trigger {
  position: absolute;
  top: 0;
  right: 0;
}

.menu-popover {
  min-width: 208px;
  height: 56px;
  padding: 8px 0;
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.menu-item {
  composes: body-2 from global;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  text-align: start;

  &:hover {
    background-color: var(--background-secondary);
  }
}

.menu-item-content {
  width: 100%;
  padding: 8px 16px;
  color: var(--content-primary-a);
}

.percent-badge {
  composes: caption from global;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 16px;
  padding: 0 4px;
  color: var(--content-secondary);
  white-space: nowrap;
  cursor: default;
  user-select: none;
  border: 1px solid var(--border-secondary);
  border-radius: 2px;
}
