.eligibility {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 504px;
  height: 100%;
}

.lottie {
  composes: mb-32 from global;
  align-self: center;
  width: 200px;
  height: 200px;
}
