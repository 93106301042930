.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;

  & h2 {
    margin-bottom: 16px;
  }
}

.bulletPointsList {
  display: grid;
  list-style: unset;
  padding: 24px;
  background-color: var(--elevation-low);
  border-radius: 8px;
  gap: 16px;
  & li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 16px;
    & svg {
      min-width: 16px;
    }
    & span {
      color: var(--content-primary-a);
    }
  }
}

.checkboxLabel {
  color: var(--content-primary-a);
}

.checkboxError {
  color: var(--error400);
}

@media only screen and (width <= 776px) {
  .header svg {
    display: none;
  }
}

.backButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.checkboxContainer {
  display: flex;
  justify-items: center;
  align-content: start;
  gap: 8px;
}

.label {
  color: var(--content-primary-a);
  margin-top: -4px;
}

.error {
  font-size: var(--body-2-font-size);
  display: flex;
  margin-left: 24px;
  margin-top: 2px;
  align-items: center;
  color: var(--content-error);
}

.error svg {
  flex-shrink: 0;
}
