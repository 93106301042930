.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  --animation-duration: 3000ms;
  --move-animation-delay: 600ms;
  --scale-animation-delay: calc(var(--animation-duration) + var(--move-animation-delay));
  --ease-out-quart-timing: cubic-bezier(0.25, 1, 0.5, 1);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%;
  margin-top: 80px;
}

.content {
  width: 504px;
  visibility: hidden;
  opacity: 0;
  animation-name: showElement;
  animation-fill-mode: forwards;
  animation-delay: var(--scale-animation-delay);
}

.illustration {
  height: 70vh;
  opacity: 0;
  animation:
    fadeIn var(--move-animation-delay) var(--ease-out-quart-timing) var(--move-animation-delay)
      forwards,
    scaleDown var(--move-animation-delay) var(--ease-out-quart-timing) var(--scale-animation-delay)
      forwards;
}

@media (prefers-reduced-motion: reduce) {
  .content {
    visibility: visible;
    opacity: 1;
  }

  .illustration {
    height: 300px;
    opacity: 1;
  }
}

@keyframes showElement {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    height: 70vh;
  }

  100% {
    height: 300px;
  }
}
