.transactionsTable {
  width: 100%;
  margin: 16px 0;
  table-layout: fixed;
  border-collapse: collapse;

  colgroup {
    col:nth-child(1) {
      width: 105px;
    }

    col:nth-child(2) {
      width: 105px;
    }

    col:nth-child(3) {
      width: 186px;
      min-width: 186px;
    }
  }

  /* Visually hide thead but leaves it in DOM for accessibility */
  thead,
  thead th {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
    border: 0;
  }

  tbody {
    tr.memorize-widget-node-row {
      border-bottom: 1px solid var(--border-tertiary);
    }

    .table-row td {
      padding: 12px 0 0;

      &:not(:first-child) {
        padding-left: 16px;
      }
    }

    .memorize-widget-node-row td {
      padding: 0 0 12px;
    }
  }
}

.loading-row {
  border-bottom: 1px solid var(--border-tertiary);

  td {
    padding: 12px 0;

    &:not(:first-child) {
      padding-left: 16px;
    }
  }
}

.loading-cell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 32px;
}

.align-right {
  align-items: flex-end;
}

.transactions-table-amount-details {
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.transactions-table-counterparty :global(p) {
  width: 105px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
