.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.content,
.illustration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 80px 48px;
  background: var(--elevation-low);
}

.content > section {
  width: 100%;
  max-width: 504px;
}

.illustration {
  padding: 160px 92px;
  background: var(--background-secondary);
}

.illustration > figure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 360px;
  max-height: 360px;
  aspect-ratio: 1;
  padding: 0;
  margin: 0;
}
