.container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
}

.title {
  composes: body-1 from global;
  color: var(--content-primary-a);
}

.subtitle {
  composes: body-2 from global;
  color: var(--content-secondary);
}
