.selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  padding: 15px;
  box-sizing: border-box;
  text-decoration: unset;
  appearance: none;
  outline: none;
  transition: all var(--transition-duration-short) ease-in-out;
  flex-grow: 1;

  & > *:not(:last-child) {
    flex-grow: 1;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);
}

.wrapper * {
  cursor: pointer;
}

.readonly {
  background-color: var(--elevation-low);
}

.readonly * {
  cursor: initial;
}

.classic {
  border: 1px solid var(--border-tertiary);
  box-shadow: var(--shadow-low);

  &:not(&.readonly):hover {
    box-shadow: var(--shadow-high);
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }
}

.disabled {
  background-color: var(--elevation-low);
  border-color: var(--border-tertiary);
  box-shadow: none;
  opacity: var(--disabled-opacity);
}

.disabled * {
  cursor: not-allowed;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.icon svg {
  width: 100%;
  height: auto;
}

.header {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.title {
  color: var(--content-primary-a);
  text-align: left;
}

.subtitle {
  color: var(--content-secondary);
  text-align: left;
}

.label {
  display: flex;
  justify-content: space-between;
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  width: inherit;
  padding: 16px;
  border-top: 1px solid var(--border-tertiary);
}

.additionalContent {
  color: var(--content-secondary);
  text-align: left;
}

.withTooltip {
  & button > div {
    flex-grow: 1
  }
}
