.main {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none;
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.table-loading {
  width: 100%;
  border-spacing: 0;
}

.pagination-loading {
  margin-top: auto;
}

.empty-loading {
  flex-grow: 1;
}
