.default-section,
.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section {
  position: relative;
  padding: 16px 32px;
  background: var(--elevation-default);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
}

.handle {
  position: absolute;
  top: 22px;
  left: 10px;
}

.item {
  composes: body-1 from global;
  padding: 4px 8px;
  color: var(--content-primary-a);
  border-radius: 8px;
}

.actions {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.icon {
  margin-right: 8px;
}
