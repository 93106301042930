.header {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  color: var(--content-primary-a);
}

.title {
  display: flex;
  align-items: center;
  margin: 0;
}

.vat {
  color: var(--content-secondary);
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trigger {
  position: relative;
  z-index: var(--z-index-default);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: var(--elevation-default);
  border: 0;
  border-radius: 4px;
  cursor: pointer;
}

.popover {
  width: 240px;
  padding: 8px 0;
  overflow: auto;
  background-color: var(--elevation-high);
  box-shadow: var(--shadow-high);
  border-radius: 4px;
}

.list-item {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  box-sizing: border-box;
  overflow: hidden;
  color: var(--content-primary-a);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-item[data-hovered='true'] {
  background-color: var(--background-secondary);
}

.list-item[data-selected='true'] {
  background-color: var(--state-selected);
}
