.btn {
  gap: 8px;
  width: max-content;

  svg {
    transition: transform 0.2s;
    rotate: -90deg;
  }
}

.is-open {
  transform: rotate(180deg);
}

.cancel-placeholder {
  width: 12px;
}

.period-cancel-btn {
  position: absolute;
  top: 50%;
  right: 16px;
  display: flex;
  padding: 0;
  border-radius: 50%;
  transform: translateY(-50%);

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }

  svg {
    width: 12px;
    height: 12px;
    color: var(--content-accent);
  }
}
