.container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.icon {
  display: flex;
}

.title-group {
  text-align: left;
}

.subtitle {
  composes: caption from global;
  color: var(--content-secondary);
}

.title {
  white-space: nowrap;
}

.info {
  color: var(--content-tertiary);
}

.error {
  color: var(--content-error);
}

.error.subtitle {
  color: var(--content-error);
}
