.btn {
  --focus-outline-color: var(--border-accent-low);
  --bg-color: var(--background-primary);
  --fg-color: var(--content-primary-b);

  border: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 16px;
  line-height: 24px;
  color: var(--fg-color);
  appearance: none;
  cursor: pointer;
  user-select: none;
  background-color: var(--bg-color);
  border-radius: 4px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    background-color: var(--overlay-primary-b-color);
    opacity: var(--overlay-opacity);
  }

  &:hover::after {
    --overlay-opacity: var(--hover-opacity);
  }

  &:active::after {
    --overlay-opacity: var(--pressed-opacity);
  }

  &[disabled],
  &[aria-disabled='true'] {
    cursor: not-allowed;

    &::after {
      border-radius: 4px;
      --overlay-primary-a-color: var(--primary-white);
      --overlay-opacity: var(--disabled-opacity);

      cursor: not-allowed;
      background-color: var(--overlay-primary-b-color);
    }

    &.btn--secondary {
      border: 1px solid var(--border-secondary);
    }
  }

  &:active::after,
  &:hover::after {
    border-radius: 4px;
  }
}

.btn:not([data-focus-visible]) {
  box-shadow: none;
}

.btn[data-hovered] {
  --overlay-opacity: var(--hover-opacity);
}

.btn[data-pressed=] {
  --overlay-opacity: var(--pressed-opacity);
}

.btn--secondary {
  --bg-color: transparent;
  --fg-color: var(--content-primary-a);
  border: 1px solid var(--fg-color);

  &::after {
    background-color: var(--overlay-primary-a-color);
  }
}

a.btn {
  text-decoration: none;
}

.btn--tertiary {
  --bg-color: none;
  --fg-color: var(--content-primary-a);
  border: 1px solid var(--fg-color);

  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;

  &:hover {
    color: var(--fg-color);
    border-bottom-color: var(--fg-color);
  }

  &:focus-visible {
    border-radius: 4px;
  }

  &[disabled]::after,
  &[aria-disabled='true']::after {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }

  &::after {
    border-radius: 0;
    background-color: var(--overlay-primary-a-color);
  }
}

.btn--danger {
  --fg-color: var(--content-primary-b-static);
  --bg-color: var(--background-error-high);

  border: none;
}

.btn--icon-only {
  --bg-color: var(--background-secondary);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 4px;

  &::after,
  &.btn::after {
    border: none;
  }

  &.btn--small {
    width: 32px;
    height: 32px;
  }

  &.btn--large {
    width: 48px;
    height: 48px;

    img,
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.btn--tertiary {
    --bg-color: transparent;

    &::after {
      border-radius: 4px;
    }
  }
}

.btn--small:not(.btn--tertiary) {
  height: 32px;
}

.btn--large:not(.btn--tertiary) {
  height: 48px;
}

.btn--stretch:not(.btn--tertiary) {
  display: flex;
  width: 100%;
}

.btn svg {
  color: var(--content-primary-a);
}

.button-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: inherit;
}

.btn-filter {
  --border-color: var(--border-secondary);
  --fg-color: var(--content-primary-a);
  --focus-outline-color: var(--border-accent-low);
  --overlay-opacity: 0;
  --border-radius: 100px;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 8px 16px;
  font-size: var(--body-1-font-size);
  font-weight: var(--body-1-font-weight);
  line-height: var(--body-1-line-height);
  color: var(--fg-color);
  user-select: none;
  background-color: var(--elevation-mid);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);

  &:hover::after {
    --overlay-opacity: var(--hover-opacity);
  }

  &:active::after {
    --overlay-opacity: var(--pressed-opacity);
  }

  &[disabled],
  &[aria-disabled='true'] {
    cursor: not-allowed;

    --overlay-primary-a-color: var(--overlay-primary-b-color);

    &::after {
      --overlay-opacity: var(--disabled-opacity);

      cursor: not-allowed;
    }
  }

  &::after {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    pointer-events: none;
    content: '';
    background-color: var(--overlay-primary-a-color);
    border-radius: var(--border-radius);
    opacity: var(--overlay-opacity);
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px var(--focus-outline-color);
  }

  &.btn--active {
    --fg-color: var(--content-primary-a);
    --border-color: var(--content-accent);
  }

  svg {
    width: 16px;
    height: 16px;
  }

  .filter-select-trigger__clear-btn {
    color: var(--content-accent);
  }
}
