.filters-wrapper {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: start;

  :global(.filter-select__content) {
    z-index: var(--z-index-mid);
  }
}

.filter-wrapper {
  padding: 4px;
}
