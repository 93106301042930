.trigger {
  width: 248px;

  &:global(.power-select-customs-trigger.block.ember-basic-dropdown-trigger--below) {
    box-shadow: none;
  }

  &:global(.power-select-customs-trigger.block svg:not(.selected-icon-svg) path) {
    fill: var(--content-secondary);
  }
}

.multiple-select-dropdown-custom {
  &:global(.power-select-custom.ember-power-select-dropdown.ember-basic-dropdown-content) {
    z-index: 99;
  }

  &:global(
      .power-select-custom.ember-power-select-dropdown.ember-basic-dropdown-content
        .ember-power-select-option
    ) {
    height: unset;
    min-height: 40px;
  }

  &:global(
      .power-select-custom.ember-power-select-dropdown.ember-basic-dropdown-content
        .ember-power-select-option[aria-disabled='true']
    ) {
    pointer-events: auto;
    cursor: default;
  }

  &:global(
      .power-select-custom.ember-power-select-dropdown.ember-basic-dropdown-content
        .ember-power-select-option[aria-disabled='true']::after
    ) {
    opacity: 0;
  }

  &:global(
      .power-select-custom.ember-power-select-dropdown.ember-basic-dropdown-content
        .ember-power-select-option[aria-disabled='true']
        button
    ) {
    pointer-events: none;
    cursor: default;
  }

  &:global(
      .power-select-custom.ember-power-select-dropdown.ember-basic-dropdown-content
        .ember-power-select-options
    ) {
    width: 360px;
    max-height: 310px;
  }
}

.approver-select-trigger {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 12px;
}
