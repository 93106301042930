.container,
.card-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.asides {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.asides > .credit-card-input {
  flex: 1;
  min-width: 200px;
}

.credit-card-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--content-primary-a);
}

.input-container {
  position: relative;
  line-height: 22px;
}

.input-field {
  padding: 8px 12px 8px 12px;
  background-color: var(--elevation-default);
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
  transition: border-color 0.5s;
}

.credit-card-input[data-disabled] .input-field {
  cursor: not-allowed;
  background-color: var(--elevation-low);
  opacity: 0.45;
}

.credit-card-input[data-invalid] .input-field {
  border-color: var(--border-error);
}

.input-field-focused {
  border-color: var(--border-accent-high);
  box-shadow: 0 0 0 4px var(--border-accent-low);
}

.input-icon {
  position: absolute;
  top: 0;
  right: 12px;
  height: 100%;
  display: flex;
  align-items: center;
}

.footnote {
  display: flex;
  align-items: center;
  align-self: end;
  gap: 8px;
  color: var(--content-secondary);
}

.input-sample {
  width: 100%;
  box-sizing: border-box;
}

.error {
  color: var(--content-error);
}
