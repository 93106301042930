.cell {
  composes: body-2 from global;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  overflow: hidden;
  color: var(--content-primary-a);
}

.cell-popover-trigger {
  display: flex;
  align-items: center;
  width: 100%;
  height: 90%;
  padding: 0;
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

.align-right .cell-actions {
  left: 0;
}

.align-left .cell-actions {
  margin-left: auto;
}

.cell:not(:hover) .cell-actions-display,
.cell-actions-display.hover-disabled {
  display: none;
}

.action-align-right {
  right: 0;
}
