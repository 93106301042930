.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -6px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.period {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-left: -12px;
}

.periodTitle {
  margin-left: 8px;
  color: var(--content-secondary);
}
