.container {
  position: relative;
  display: flex; /* required for negative margin on image */
  width: fit-content;
  height: fit-content;
}

.avatar {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  background: var(--background-secondary);
  border-radius: 50%;

  &.disabled {
    opacity: 0.45;
  }
}

.icon {
  position: absolute;
  right: -4px;
  bottom: -4px;
  border-radius: 50%;
}

.status-icon-16 {
  width: 16px;
  height: 16px;
}
