.container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4px 12px;
  padding: 16px;
  background: var(--elevation-low);
  color: var(--content-primary-a);
  border-radius: 8px;
}

.container.elevation-high {
  background: var(--elevation-high);
}

.price-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.price-note {
  color: var(--content-secondary);
}

.loader-container {
  display: flex;
  align-items: center;
  height: 16px;
  margin-bottom: 8px;
}
