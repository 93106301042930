.container {
  text-align: right;
}

.amount {
  composes: body-1 from global;
  color: var(--content-primary-a);

  &.credit {
    color: var(--content-success);
  }
}

.subtitle {
  composes: body-2 from global;
  color: var(--content-secondary);
}
