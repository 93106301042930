.option {
  composes: body-1 from global;
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--content-primary-a);
  list-style: none;
}

.checkmark {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
}
