.badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 6px;
  color: var(--content-primary-a);
  user-select: none;
  border-radius: 4px;
}

.icon-only {
  padding: 4px;
}

.purple {
  background-color: var(--background-activate);
}

.mint {
  background-color: var(--background-free-trial);
}

.peach {
  background-color: var(--background-upgrade);
}

.gray {
  background-color: var(--background-tertiary);
}

.yellow {
  background-color: var(--background-feature-discoverability);
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

body.dark-theme .icon {
  filter: invert(1);
}

.no-margin {
  margin-right: 0;
}

.margin-top {
  margin-top: -2px;
}
