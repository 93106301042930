.header-button {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  opacity: 0;
}

.header-button .chevron-icon {
  transform: rotate(0deg);
}

.header-button[data-pressed] {
  opacity: 1;
}

.header-button[data-pressed] .chevron-icon {
  transform: rotate(180deg);
}

.header-button-active {
  display: flex !important;
  opacity: 1;
}

.arrow-icon {
  width: 12px;
  height: 12px;
}
