.value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.missing {
  color: var(--content-secondary);
}

.icon {
  width: auto;
  height: 12px;
  margin-right: 12px;
}

.warning-icon {
  composes: icon;
  color: var(--content-warning);
}

.error-icon {
  composes: icon;
  color: var(--content-error);
}
