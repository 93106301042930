.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  min-height: 404px;
  padding: 24px;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);

  @media only screen and (width < 980px) {
    min-height: auto;
  }
}

.container:hover {
  box-shadow: var(--shadow-high);
}

.container:focus {
  box-shadow: 0 0 0 4px var(--border-accent-low);
}

.logo {
  display: block;
  width: 56px;
  height: 56px;
  margin-bottom: 16px;
}

.name {
  composes: title-4 from global;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.metadata {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 0;

  > * {
    flex: 1;
    margin: 0;
  }

  sup {
    font-size: 0.6em;
  }
}

.risks-container {
  flex-basis: 100%;
}

.label {
  composes: caption from global;
  margin-bottom: 4px;
  color: var(--content-secondary);
}

.risks {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 0;
}

.tag {
  composes: tag from global;
  white-space: nowrap;
}
