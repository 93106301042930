.tab {
  padding: 16px 0;
  cursor: default;
  outline: none;
  position: relative;
  color: var(--content-secondary);
  transition: color 200ms;
  cursor: pointer;

  &[data-hovered],
  &[data-focused] {
    color: var(--content-primary-a);
    border-bottom: 3px solid var(--border-primary);
  }

  &[data-selected] {
    color: var(--content-primary-a);
    border-bottom: 3px solid var(--border-primary);
  }

  &[data-disabled] {
    color: var(--text-color-disabled);

    &[data-selected] {
      --border-color: var(--text-color-disabled);
    }
  }

  &[data-focused] {
    box-shadow: none;
  }

  &[data-focus-visible] {
    outline: none;
    box-shadow: 0 0 0 4px var(--border-accent-low);
    border-radius: 4px;
  }
}

.has-icon {
  display: flex;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;

  svg {
    margin-right: 12px;
  }
}

.notification-dot {
  position: absolute;
  left: 19px;
  top: 18px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--background-error-high);
  border-radius: 50%;
  outline: 1px solid var(--elevation-default);
}

.with-divider {
  position: relative;
  margin-right: 24px;
}

.with-divider::after {
  content: '';
  position: absolute;
  right: -24px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 16px;
  background-color: var(--border-tertiary);
  pointer-events: none;
}
