.counterparty-avatar {
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.counterparty-subtitle {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 105px;
  height: 24px;

  p {
    width: 80px;
  }
}
