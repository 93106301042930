.illustration {
  width: 200px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 48px;
}

.content {
  width: 416px;
  margin-right: 64px;
}

.actions {
  display: flex;
  align-items: center;
}
