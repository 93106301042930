.card {
  padding: 16px;
  margin-top: 16px;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
}

.name {
  color: var(--content-primary-a);
}

.street,
.zipcode-city-country,
.email {
  color: var(--content-secondary);
}

.overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
