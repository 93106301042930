.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 48px;
  color: var(--content-primary-a);
  border-bottom: 1px solid var(--border-tertiary);
}

.actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.actions .button {
  padding: 12px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 4px;
}

.step {
  padding: 2px 8px;
  color: var(--content-secondary);
}

.stepActive, .stepDisabled {
  cursor: default;
}

.stepActive {
  color: var(--content-primary);
}

.stepDisabled {
  color: var(--content-tertiary);
}