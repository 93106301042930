.columnNames {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: flex;
}

.itemContent {
  display: flex;
  align-items: center;
  flex: 1;
}

.input {
  width: 200px;
}
