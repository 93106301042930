.header-cell {
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  height: 40px;
  padding: 0 24px 0 0;
  padding: 12px 16px;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  background: var(--elevation-default);
  border-bottom: 1px solid var(--border-tertiary);
}

.header-cell:first-child {
  padding-left: 48px;
}

.header-cell:last-child {
  padding-right: 48px;
  text-align: right;
}

.header-content {
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);
}

.amount {
  text-align: right;
}

.active {
  color: var(--content-primary-a);
}

.active > .header-content {
  color: var(--content-primary-a);
}

.sort-icon {
  display: inline;
  width: 8px;
  height: 8px;
}

.active .sort-icon {
  color: var(--content-primary-a);
}
