.selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 72px;
  padding: 15px;
  text-decoration: unset;
  appearance: none;
  cursor: pointer;
  background-color: var(--elevation-high);
  border-radius: 4px;
  outline: none;
  transition: all var(--transition-duration-short) ease-in-out;
}

.classic {
  border: 1px solid var(--border-tertiary);
  box-shadow: var(--shadow-low);

  &:hover {
    box-shadow: var(--shadow-high);
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }
}

.disabled {
  cursor: not-allowed;
  background-color: var(--elevation-low);
  border-color: var(--border-tertiary);
  box-shadow: none;
  opacity: var(--disabled-opacity);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.icon svg {
  width: 100%;
  height: auto;
}

.header {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.title {
  color: var(--content-primary-a);
  text-align: left;
}

.subtitle {
  color: var(--content-secondary);
  text-align: left;
}

.content {
  color: var(--content-secondary);
  text-align: left;
}

.action-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 16px;
  height: 16px;
  margin-left: 24px;
  background: transparent;

  svg {
    width: auto;
    height: 16px;

    path {
      fill: var(--content-secondary);
    }
  }
}
