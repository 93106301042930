.balanceTable {
  composes: body-1 from global;
  width: 100%;
  color: var(--content-primary-a);
  table-layout: fixed;
  border-spacing: 0;

  /* Visually hide thead but leave it for accessibility */
  thead,
  thead th {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
    border: 0;
  }

  tbody :is(td, th) {
    position: relative;
    height: 56px;
    padding: 16px 16px;
    background-color: var(--elevation-low);
    isolation: isolate;

    &.current {
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
        content: '';
        background-color: var(--overlay-primary-a-color);
        border-radius: inherit;
        opacity: var(--hover-opacity);
      }
    }
  }
}

.rowHeader {
  composes: body-1 from global;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px 0 0 4px;
  box-shadow: var(--shadow-low);
}

.rowCell {
  border-top: 1px solid var(--border-tertiary);
  border-bottom: 1px solid var(--border-tertiary);
}

.rowCell:last-child {
  border-right: 1px solid var(--border-tertiary);
  border-radius: 0 4px 4px 0;
}

.forecastAmount {
  max-width: 100%;
  padding-right: 1px;
  overflow: auto;
  font-style: italic;
  color: var(--content-secondary);
}
